@import "./mixin/mixin";
@import "./variables/fonts";
@import "./variables/colors";

* {
  margin: 0;
  padding: 0;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(187, 194, 201, 0.70);
  border-radius: 5px;
}

.number_block--list_block::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #E2E8F0 !important;
  border-radius: 5px;
}

.number_block--list_block::-webkit-scrollbar-thumb {
  background-color: #94A3B8 !important;
  border-radius: 5px;
}

.header {
  height: 50px;
  background: rgb(36, 63, 126);
  background: linear-gradient(90deg, rgba(36, 63, 126, 1) 0%, rgba(47, 10, 109, 1) 100%);

  .flex_box {
    width: 100%;
    @include flex(row, space-between, center);

    .operator_connect {
      @include bgk_image(url("images/operator-connect.png"), contain, center);
      width: 220px;
      height: 30px;
    }

    .microsoft_teams {
      @include bgk_image(url("images/microsoft.png"), contain, center);
      width: 150px;
      height: 30px;
    }
  }
}

.bluip_logo {
  background-image: url("../styles/images/bluip-gray.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 30px;
  @include flex(row, center, center);
  position: absolute;
  bottom: 35px;
}

.container {
  // height: calc(100vh - 50px);
  height: calc(100vh - 76px);
  width: 100%;
  overflow-y: auto;

  .container_header {
    position: absolute;
    width: 100%;
    height: 230px;
    background: rgb(76, 120, 251);
    background: linear-gradient(90deg, rgba(76, 120, 251, 1) 0%, rgba(97, 5, 214, 1) 100%);
  }

  .container_section {
    @include flex(row, center, center);
  }

}

.container_footer {
  // position: absolute;
  // bottom: 5px;
  margin-bottom: 5px;
  left: 0;
  right: 0;
  @include flex(row, center, center);

  .footer_text {
    color: $text_grey;
    font-size: $medium;
    font-weight: 300;
  }

  .text_pt {
    color: $periwinkle_blue;
    font-size: $medium;
    font-weight: 300;
    text-decoration: none;
    margin: 0 10px;
    cursor: pointer;
  }

  .text_t {
    margin-left: 0px;
  }
}

.section {
  position: relative;
  margin-top: 0;
  overflow-x: auto;
  margin-left: 20px;
  margin-right: 20px;
  padding-right: 5px;

  .section_title {
    color: $white;
    margin-top: 12px;

    .title {
      font-size: 24px;
    }

    .subtitle {
      font-size: 14px;
    }

    .subtitle_link {
      color: $primary_purple !important;
      text-decoration: underline;
      text-decoration-color: $primary_purple !important;
    }
  }

  .section_container {
    padding: 20px;
    margin-top: 12px;
    width: 900px;
    margin-bottom: 20px;
    margin-left: 5px;
    @media screen and (max-width: 1000px) {
      width: 700px;
    }

    .grid_stepper {
      @include flex(row, center, center);
      margin-bottom: 20px;

      .stepper {
        width: 50%;
      }
    }

    .box_customer_info {
      width: 100%;
      margin-top: 24px;

      .grid_customer_info {
        //max-height: 280px;
        overflow-y: auto;
        margin-bottom: 25px;

        &::-webkit-scrollbar {
          width: 5px;
          color: #636363;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #636363;
        }

        &::-webkit-scrollbar-track {
          background: gainsboro;
          border-radius: 5px;
        }
      }

      .customer_info_input_list {
        display: flex;
        grid-gap: 16px;
        margin-top: 16px;

        #state_province {
          max-width: calc(25% - 16px);
        }
      }

      .customer_info_input_tel_code {
        font-size: 13px;
      }

      .customer_info_input {
        width: 100%;
      }
    }

    .box_button_search {
      @include flex(row, flex-end, baseline);
    }

    .search_box {
      @include flex(row, flex-end, center);
      width: 100%;
      grid-gap: 70px;
    }

    .box_list {
      @include flex(row, flex-end, center);
      width: 100%;
      grid-gap: 70px;
      margin-top: 20px;

      .list_title {
        color: $periwinkle_blue;
        font-size: $small;
        margin-bottom: 4px;
      }

      .list {
        overflow-y: auto;
        height: 26vh;
        background-color: $bgk_gray;

        &::-webkit-scrollbar {
          width: 5px;
          color: #636363;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #636363;
        }

        &::-webkit-scrollbar-track {
          background: gainsboro;
          border-radius: 5px;
        }

        .arrow {
          @include flex(row, center, center);
          margin-left: -12px;
          color: $text_grey;

        }

        .list_item, .collapse {
          display: block;
        }
      }
    }

    .box_list_warnings {
      @include flex(row, flex-end, center);
      width: 100%;
      grid-gap: 70px;
      margin-top: 5px;

      .error_alert {
        color: $error;
        font-size: $small;
        padding: 0 16px;
        @media screen and (max-width: 1000px) {
          font-size: $x_small;
        }
      }

      .warning_alert {
        color: $orange;
        font-size: $small;
        padding: 0 16px;
        @media screen and (max-width: 1000px) {
          font-size: $x_small;
        }
      }
    }

    .box_button {
      @include flex(row, center, center);
      width: 100%;
      grid-gap: 16px;
      margin-top: 5px;
    }
  }
}

//Modals
.modal_box {
  @include flex(column, start, center);
  padding-right: 20px;
  padding-left: 20px;
  height: 350px;
  width: 380px;

  &--congrates {
    @include flex(column, flex-start, center);
    margin: 20px 0;
  }

  &--error {
    @include flex(column, flex-start, center);
    margin: 20px 0;
  }

  .modal_phone_img {
    background-image: url("../styles/images/phone.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 110px;
    height: 110px;
    display: flex;
  }

  .modal_check_img {
    background-image: url("../styles/images/check.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 65px;
    height: 65px;
    display: flex;
  }

  &--check_img {
    background-image: url("../styles/icons/congrates.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    display: flex;
  }

  &--error_img {
    background-image: url("../styles/icons/alert.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    display: flex;
    margin-bottom: 30px;
  }

  .warning_img {
    background-image: url("../styles/images/exclaimer.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 65px;
    height: 65px;
    display: flex;
  }

  &--title {
    color: $text_dark;
    font-size: $small_title !important;
    padding: 0 0 16px 0;
    text-align: center;
    font-weight: 600 !important;;
  }

  &--confirm {
    @include flex(column, start, start);
  }

  &--list-block {
    margin-bottom: 40px;
  }

  &--subtitle {
    font-size: $medium !important;
    color: $primary_black !important;
    line-height: 1.4;

    &::before {
      content: url("icons/dot.svg") !important;
      width: 12px !important;
      height: 12px !important;
      margin-right: 6px !important;
    }
  }

  &--warning-block {
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(251, 231, 231, 0.5);
    border-left: 4px solid #F44336;
    padding: 16px 20px;
    margin-bottom: 30px;
  }

  &--warning-title {
    color: $primary_black;
    font-size: $normal !important;
    font-weight: 600 !important;
    text-transform: capitalize;
    margin-bottom: 6px !important;
  }

  &--warning-subtitle {
    color: $primary_black;
    font-size: $medium !important;
  }

  &--text {
    color: $primary_black;
    font-size: $medium !important;
    font-weight: 600 !important;
    max-width: 800px;
  }

  .modal_title {
    color: $text_dark;
    font-size: $small_title;
    padding: 16px 0;
    text-align: center;
    font-weight: 600;
  }

  .modal_subtitle {
    font-size: $medium;
    max-width: 350px;
    text-align: center;
    color: $text_grey;
    line-height: 1.4;
  }

  .number_type_quantity {
    color: $periwinkle_blue;
    font-weight: bold;
  }

  .voice_app_numbers {
    margin-bottom: 20px;
  }

  .e911dislosure_title {
    color: $bgk-pink;
    font-size: 18px;
  }

  .e911dislosure_text {
    color: $bgk-pink;
    font-weight: 320;
    margin-bottom: 20px;
  }

  &--setup_title {
    color: $primary_black;
    font-size: $large_title !important;
    padding: 16px 0;
    text-align: center;
    line-height: 1.3;
    font-weight: 600;
    margin-top: 40px;
  }

  &--setup_subtitle {
    color: #5E6366 !important;
    font-size: 14px !important;
    width: 304px;
    text-align: center;
  }

  &--setup_span {
    color: #5E6366 !important;
    font-size: 14px !important;
    width: 410px;
    text-align: center;
  }

  .setup_modal_title {
    color: $text_dark;
    font-size: $small_title;
    padding: 16px 0;
    text-align: center;
    line-height: 1.3;
    font-weight: 600;
    margin-top: 40px;
  }

  .setup_modal_subtitle {
    font-size: $medium;
    max-width: 350px;
    text-align: center;
    color: $text_grey;
    padding-bottom: 10px;
  }

  &--subtitle_link {
    font-size: $medium !important;
    color: $primary_purple !important;
    text-decoration-color: $primary_purple !important;
    line-height: 1.5 !important;
  }

  &--warning_text {
    line-height: 2 !important;
  }
}

//Papers
.paper_box {
  width: 515px;
  height: 415px;
  position: relative;
  @include flex(column, center, center);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 60px;


  .warning_img {
    background-image: url("../styles/images/exclaimer.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 110px;
    height: 110px;
    display: flex;
  }

  .paper_title {
    color: $text_dark;
    font-size: $small_title;
    padding: 16px 0;
    text-align: center;
    font-weight: 600;
  }

  .paper_subtitle {
    font-size: $medium;
    max-width: 340px;
    text-align: center;
    color: $text_grey;
    line-height: 1.8;
  }

  .paper_link {
    color: $periwinkle_blue;
    text-decoration: none;
  }

  .paper_button_box {
    margin-top: 50px;
    @include flex(row, center, center);
    grid-gap: 20px;
  }

}

.start_trial {
  @include flex(column, space-between, start);
  width: -webkit-fill-available;
  height: calc(100% - 114px);
  border-radius: 20px;
  border: 1px solid #DDE2E5;
  padding: 30px;
  margin: 30px;
  box-shadow: none;

  &--block {
    height: 80%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  &--title {
    font-size: $large_title !important;
    color: $primary_black;
  }

  &--description {
    font-size: $large !important;
    text-align: justify;
    color: $text_grey !important;
    line-height: 1.5 !important;
    max-width: 856px;
    margin-bottom: 20px !important;
  }

  &--subtitle {
    font-size: $large !important;
    text-align: start;
    color: $primary_black;
    line-height: 1.5 !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  &--subtitle_link {
    font-size: $large !important;
    color: $primary_purple !important;
    text-decoration-color: $primary_purple !important;
    line-height: 1.5 !important;
  }

  &--step_title {
    font-size: $small_title !important;
    text-align: start;
    color: $primary_black;
    font-weight: bold !important;
    line-height: 2 !important;
  }

  &--step_block {
    margin: 20px !important;
  }

  &--step {
    padding: 12px 0 !important;
    font-size: $small;
    color: $text_grey;
    line-height: 2;

    .step_number {
      color: $text_dark;
      font-weight: bold;

    }

    &.info {
      margin-left: 64px;
    }
  }

  .step_button_block {
    width: 100%;
    @include flex(row, center, center);
  }
}

.step2Subtitle {
  font-size: $small !important;
  color: $text_grey;
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.arrow-down {
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;

}

.rotate-arrow {
  transform: rotate(180deg);
}

.hide, .hide-arrow {
  opacity: 0;
}

.show:hover .hide, .showSortedColumnArrow {
  opacity: 1
}


.search_icon {
  background-image: url("../styles/images/icons-search.svg");
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: flex;
}

.search {
  background-image: url("icons/search.svg");
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: flex;
}

.close_icon {
  background-image: url("../styles/images/close.svg");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  margin-right: 12px;
  cursor: pointer;
}

.hover_day:has([data-hover=true]) {
  background-color: #F6F9FF !important;
}

.rdp {
  --rdp-cell-size: 30px !important; /* Size of the day cells. */
  --rdp-caption-font-size: 14px !important; /* Font size for the caption labels. */
  --rdp-accent-color: #4A85FF !important; /* Accent color for the background of selected days. */
  --rdp-background-color: #e7edff; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #F6F9FF !important;
  border-radius: 4px !important;
  color: #4A85FF !important;
}

/*Login*/
.login_container {
  @include flex(row, center, center);
  height: 100vh;
  width: 100%;

  .login_block {
    @include flex(column, space-between, center);
    min-width: 500px;
    height: 600px;
    padding: 0px 20px 15px;

    &--shadow {
      width: 100%;
      height: -webkit-fill-available;
      box-shadow: 0px 10px 17.78px -7.11px rgba(0, 0, 0, 0.12);
      @include flex(column, space-around, center);
    }

    &--cover {
      background-image: url("../styles/images/Rocket.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain;
      width: 500px;
      height: 500px;
    }
    &--heading {
      text-align: center;
      font-size: 20px;
      line-height: 1.5;
      color: #262626;
    }

    &--paragraph {
      text-align: center;
      font-size: 14px !important;
      color: #79747E;
      line-height: 1.5;
    }

    &--button {
      width: 334px;
      height: 40px;
      background-color: #4A85FF !important;
      font-size: 12px !important;
    }

    &--footer_text {
      font-size: 10px !important;
      color: #848A91;
      letter-spacing: 0.52px !important;
    }

    &--footer_text_pt {
      color: #4A85FF !important;
      text-decoration-color: #4A85FF !important;
      font-size: 10px;
      letter-spacing: 0.52px;
      margin: 0 5px;
    }
  }
}

/*Access Denied*/
.access_denied {
  @include flex(row, center, center);
  width: 100%;
  height: 100vh;

  &--alert {
    background-image: url("../styles/icons/alert.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  &--block {
    @include flex(column, center, center);
    width: 550px;
    padding: 30px 20px;
    border-radius: 25px !important;
    box-shadow: 0 12.97px 19.45px -3.89px rgba(0, 0, 0, 0.12),
    0 5.19px 7.78px -5.19px rgba(0, 0, 0, 0.10),
    0 4.89px 25.93px -10.19px rgba(0, 0, 0, 0.12) !important;
  }

  &--heading {
    font-size: 22px !important;
    font-weight: 600 !important;
    margin-bottom: 8px !important;
  }

  &--paragraph {
    font-size: 14px !important;
    font-weight: lighter !important;
    color: #232C3C !important;
    margin-bottom: 30px !important;
    text-align: center !important;
  }

  &--link {
    color: #232C3C !important;
    text-decoration-color: #232C3C !important;
    letter-spacing: 0.52px;
    margin: 0 5px !important;
  }

  &--button {
    color: #fff !important;
    background-color: #4A85FF !important;
    border-radius: 4px !important;
    width: 150px;
    height: 50px;
    font-size: 16px !important;
    text-transform: capitalize !important;
  }
}

/*Login In Progress*/
.login_progress_block {
  @include flex(column, flex-start, center);

  &--waiting {
    background-image: url("../styles/icons/cosmo_1.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 350px;
    height: 250px;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  &--loader {
    background-image: url("../styles/icons/Loader.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    margin-bottom: 40px;
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &--paragraph {
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333333 !important;
  }
}

/* Number Management*/
.main_container {
  display: flex;
  height: 100%;

  .header_block {
    @include flex(row, space-between, center);
    border-bottom: 1px solid $lightgrey;
    height: 54px;

    .chevron_left, .chevron_right {
      background-image: url("../styles/icons/sidebar_left_arrow.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }

  }

  .drawer_header {
    flex-direction: row;

    .oc_logo {
      background-image: url("../styles/icons/OC_logo.svg");
      background-position: center;
      background-size: 180px;
      background-repeat: no-repeat;
      width: 100%;
      height: 73px;
      background-color: #082851;
    }

    .oc_logo_small {
      background-image: url("../styles/icons/OC_logo_small.svg");
      background-position: center;
      background-size: 54px;
      background-repeat: no-repeat;
      width: 100%;
      height: 73px;
      background-color: #082851;
    }

  }

  .list {
    height: 100%;
    margin-top: 64px;
    .item_icon {
      background-image: url("../styles/icons/call.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-right: 18px;
      min-width: 0;
    }
  }

  .sidebar_bottom_text {
    font-size: 10px !important;
    color: #fff;
    text-align: center;

    .sidebar_bottom_span {
      color: #4A85FF;
    }
  }
  .main_section {
    width: 100%;
    height: 100vh;

    .main_header {
      height: 54px;
      @include flex(row, end, center);

      .teams_icon {
        background-image: url("../styles/icons/Microsoft-Teams-Emblem.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 120px;
        height: 30px;
        margin-right: 18px;
      }

      .user_block {
        float: right;
      }

      .user_circle {
        background-image: url("../styles/icons/user_circle.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        margin-right: 18px;
      }

      .user_name {
        font-size: 14px;
        color: $primary_black;
        font-weight: 500;
        margin-right: 16px;
      }
    }

    .table_section {
      padding-left: 20px;

      .tab_block {
        border-bottom: 1px solid #ebebeb;
      }

      .search_block {
        margin: 10px 0;
      }

      .alert_block {
        position: absolute;
        right: 14px;
        z-index: 999;

        &--section {
          border: 1px solid #99D04F;
        }
      }

      .table_block {
        &--height {
          height: calc(100vh - 200px);
          @include flex(column, space-between, flex-start);
        }

        &--inner {
          @include flex(row, space-between, flex-start);
          gap: 24px;
        }

        .filter_block {
          .filter {
            background-image: url("../styles/icons/filter -1.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;

            &.active {
              background-image: url("../styles/icons/filter.svg");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              width: 18px;
              height: 18px;
            }

            &.disabled {
              background-image: url("../styles/icons/filter_disabled.svg");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              width: 18px;
              height: 18px;
            }
          }
        }

        .filter_disabled {
          background-image: url("../styles/icons/filter_disabled.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 18px;
          height: 18px;
          align-self: center;
        }

        .sort_default {
          background-image: url("../styles/icons/Swap.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;

          &.sort_down {
            background-image: url("../styles/icons/Swap-1.svg");
          }

          &.sort_up {
            background-image: url("../styles/icons/Swap-2.svg");
          }

        }

        // .sort_active{
        //   background-image: url("../styles/icons/Swap-1.svg");
        //   background-position: center;
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   width: 20px;
        //   height: 20px;
        // }
      }

      .button_block {
        @include flex(row, space-between, center);
        margin-right: 16px;
        @media screen and (max-width: 810px) {
          @include flex(column, space-between, flex-start);
          margin-bottom: 12px;
        }
      }

      .empty_block {
        @include flex(column, center, center);
        width: 100%;
        position: absolute;
        height: 58vh;

        .search_icon {
          background-image: url("../styles/icons/empty.svg");
          background-size: 80px;
          background-position: center;
          background-repeat: no-repeat;
          width: 80px;
          height: 80px;
          display: flex;
        }

        &--title {
          color: $primary_black;
          font-size: 18px;

        }
      }
    }

    .pagination_block {
      @include flex(row, space-between, center);
      padding: 8px;
      border: 0.5px solid #E5E6EA;
      margin-right: 16px;
      width: -webkit-fill-available;

      &--items {
        font-size: 14px;
        color: #747474;
        padding-right: 12px;
      }

      &--rows {
        @include flex(row, space-between, center);
      }
    }

  }
}

.datapicker_block{
  padding: 4px 10px;
  border-bottom: 2px solid #4a85ff;
}
.data_picker_block {
  @include flex(row, center, flex-start);
  padding: 12px 24px;

  &--shortcuts {
    width: 250px;
  }
.appliedDate{
  color: #4A85FF !important;
}
  &--custom_date {
    @include flex(row, space-between, center);

    .date_label {
      font-size: 14px;
      color: #232C3C;
    }

    .right_arrow {
      background-image: url("../styles/icons/cheveron-right.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
    }
  }
}

.npa_search_block {
  padding: 10px;

  .search_field {
    border-bottom: 1px solid #D9DDEA;
    width: 250px;

    .npa_label {
      font-size: 12px;
      color: #5E6366;
    }
  }

  .npa_list {
    border-bottom: 1px solid #D9DDEA;
  }
}

.unchecked_icon {
  background-image: url("../styles/icons/unchecked.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;

}

.checked_icon {
  background-image: url("../styles/icons/checkbox.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.calendar_icon {
  background-image: url("../styles/icons/Calendar.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.arrow-down {
  background-image: url("../styles/icons/chevron-down.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.custom_calendar_block {
  @include flex(column, space-between, end);
  min-height: 300px;
  width: 250px;
  border-left: 1px solid #D9DDEA;
  margin-left: 20px;
}

.calendar_block {
  @include flex(column, flex-start, flex-start);
  padding-left: 22px;
  width: 250px;


  &--label {
    margin-bottom: 8px !important;
    font-size: $medium !important;
    color: $primary_black !important;
    font-weight: 600 !important;
  }

  .clear_icon {
    background-image: url("../styles/icons/Clear.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .calendar_icon {
    background-image: url("../styles/icons/Calendar.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  &--error {
    font-size: $small !important;
    color: $error !important;
    margin-top: 8px !important;
  }
}
.button_block {
  @include flex(row, flex-end, center);

  .secondary {
    font-size: 14px;
    color: $primary_black !important;
    text-transform: capitalize;
    min-width: 54px;
    padding: 6px 0px;

    &:hover {
      background-color: transparent;
      color: #111827;
      padding: 0;
    }
  }

  .primary {
    font-size: 14px;
    color: $primary_purple !important;
    text-transform: capitalize;
    min-width: 54px;
    padding: 6px 0px;

    &:hover {
      background-color: transparent;
      color: #4a85ff;
      padding: 0;
    }
  }
}

.step_block_container {
  height: calc(100% - 84px);;

  .step_block {
    @include flex(column, space-between, start);
    width: -webkit-fill-available;
    height: calc(100% - 0px);
    border-radius: 20px;
    border: 1px solid #DDE2E5;
    padding: 18px;
    margin: 18px;
    box-shadow: none;
  }
}

.stepper_block {
  @include flex(row, flex-end, center);
  margin-bottom: 5px;

  &--success {
    font-size: $medium !important;
    color: $primary_purple;
  }

  &--error {
    font-size: $medium !important;
    color: $error;
  }

  &--text {
    font-size: $medium !important;
    color: #83898C;
  }

  &--text_active {
    font-size: $medium !important;
    color: $primary_purple;
  }
}

.flex_justify {
  width: 100%;
  height: calc(100% - 10%);
  @include flex(column, space-between, normal);
  margin-bottom: 20px;
}

.dialog_title {
  font-size: $large_title !important;
  color: $primary_black;
}

.step_content{
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.form_title {
  margin: 14px 0;
  border-left: 1px solid #C1C7DE;
  padding: 4px 24px;

  &--step-1, &--step-2 {
    font-size: $normal !important;
    color: $primary_black;
    margin: 6px 0 !important;
    font-weight: bold !important;
  }

  &--subtitle_link {
    color: $primary_purple !important;
    text-decoration: underline;
    text-decoration-color: $primary_purple !important;
  }

}

.form_block {
  margin-bottom: 20px;

  .search_icon {
    background-image: url("../styles/icons/search_white.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    color: #fff;
  }

  &--search {
    width: 60px !important;
    height: 48px !important;
    color: #fff !important;
    background-color: #99D04F !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  &--loader {
    color: #fff !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.number_block {
  @include flex(row, center, center);
  border: 1px solid #CFD2D4;
  border-radius: 8px;

  &--available {
    width: 50%;
    border-right: 1px solid #CFD2D4;
    padding: 16px 32px;
  }

  &--title {
    font-size: 14px !important;
    color: #5E6366;
    padding-bottom: 8px;
  }

  &--claimed {
    width: 50%;
    padding: 16px 32px;
    position: relative;

    &__error {
      font-size: 13px !important;
      color: $error;
      position: absolute;
      bottom: 3%;
    }
  }

  &--list_block {
    height: 200px;
    min-width: 250px;
    overflow-y: auto;
    margin-bottom: 12px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @media screen and (max-width: 1400px) {
      height: 170px;
    }
  }

  &--list_block_claimed {
    height: 250px;
    min-width: 250px;
    overflow-y: auto;
    margin-bottom: 12px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @media screen and (max-width: 1400px) {
      height: 200px;
    }
  }

  &--no-found {
    font-size: 12px !important;
    color: #5E6366;
    text-align: center;
    margin: 64px auto !important;
    width: 280px;
  }

  &--num_text {
    color: #83898C;
  }
}

.button_block {
  &--number {
    @include flex(row, center, center);
    gap: 44px;
    width: 100%;
  }
}

.progress_bar {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  height: 58vh;

  .loader {
    background-image: url("../styles/icons/Loader.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.center{
  @include flex(column, flex-start, center);
}
.leftAlign{
  @include flex(column, flex-start, flex-start);
}
.step3_block {
  height: 100%;
  width: 100%;
  margin-top: 20px;


  .loader {
    background-image: url("../styles/icons/Loader.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-top: 22%;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.delete_dialog_block {
  @include flex(column, space-between, center);
  height: 100%;

  &--icon {
    background-image: url("../styles/icons/delete_dialog_icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px !important;
    height: 100px !important;
  }

  &--icon-note {
    background-image: url("../styles/icons/delete_dialog_note.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px !important;
    height: 100px !important;
  }

  &--icon-inprogress {
    background-image: url("../styles/icons/inprogress_logo.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px !important;
    height: 100px !important;
  }

  &--text-block {
    @include flex(column, space-between, center);
    height: 60%;
  }

  &--text-block-list {
    @include flex(column, space-between, center);
    width: 100%;
    height: 60%;
  }

  &--text-block-error {
    @include flex(column, flex-start, center);
    height: 46%;
  }

  &--text-block-success {
    @include flex(column, flex-start, center);
    height: 46%;
    width: 74%;
  }

  &--text-block-note {
    @include flex(column, flex-start, center);
    height: 60%;
  }

  &--text-box {
    @include flex(column, space-between, center);
    width: 100%;
  }

  &--support {
    @include flex(column, space-between, center);
    gap: 6px;
  }

  &--support-paragraph {
    font-size: 14px !important;
    color: #333333;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
  }

  &--support-span {
    font-size: 14px !important;
    color: #333333 !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
  }

  &--support-contact {
    @include flex(row, center, center);
    gap: 10px;
  }

  &--phone {
    background-image: url("../styles/icons/phone.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px !important;
    height: 20px !important;
  }

  &--email {
    background-image: url("../styles/icons/mail.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px !important;
    height: 20px !important;
  }

  &--dot {
    background-image: url("../styles/icons/dot.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 10px !important;
    height: 10px !important;
  }
  &--dot-gray{
    background-image: url("../styles/icons/dor_gray.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 10px !important;
    height: 10px !important;
  }


.center{
  justify-content: center;
}
.leftAlign{
  justify-content: flex-start;
}
  &--grid-container {
    //min-height: 44px !important;
    overflow: scroll !important;
    max-height: 75px !important;
    min-height: 75px !important;
    gap: 8px;
    max-width: 700px;
    align-items: flex-start;
  }

  &--grid-container-align-left {
    display: grid !important;
    grid-template-columns: repeat(5, 135px)!important;
    min-height: 44px !important;
    overflow: scroll !important;
    max-height: 75px !important;
    justify-content: flex-start;
    gap: 4px;
    align-items: flex-start;
  }

  &--grid-block {
    @include flex(column, center, center);
    width: 100%;
    padding: 0 20px;
    gap: 6px;
  }

  &--grid {
    @include flex(row, center, center);
    padding: 2px 0 !important;
    width: 115px !important;
  }

  &--grid-paragraph {
    font-size: 14px !important;
    color: #5E6366;
    margin-left: 6px !important;
  }

  &--title {
    font-size: 22px !important;
    color: #333333;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    margin-bottom: 12px !important;
  }

  &--paragraph {
    font-size: 16px !important;
    color: #5E6366;
    letter-spacing: 0.5px !important;
    text-align: center;
    line-height: 26px !important;
    margin-bottom: 12px !important;
  }

  &--paragraph-number {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #5E6366;
    letter-spacing: 0.5px !important;
    text-align: center;
    line-height: 34px !important;
  }

  &--note-paragraph {
    font-size: 16px !important;
    color: #5E6366;
    letter-spacing: 0.5px !important;
    text-align: center;
    line-height: 24px !important;
    margin-bottom: 30px !important;
    width: 88%;
  }

  &--paragraph-width {
    font-weight: 600 !important;
    font-size: 15px !important;
    color: #5E6366;
    letter-spacing: 0.5px !important;
    text-align: center;
    line-height: 24px !important;
    width: 70%;
  }

  &--note {
    background-color: #F6F9FF;
    padding: 40px 80px 40px 20px;
    border-left: 3px solid $periwinkle_blue;
    margin-bottom: 10px !important;

  }

  &--warning {
    width: 100%;
    background-color: #F6F9FF;
    padding: 16px 20px;
    border-left: 3px solid #FFC700;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  &--warning_title {
    font-size: 18px !important;
    color: #333333;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    margin-bottom: 6px !important;
  }

  &--warning_span {
    font-size: 16px !important;
    color: #333333;
    letter-spacing: 0.5px !important;
    margin-bottom: 6px !important;
  }

  &--warning_red {
    font-size: 14px !important;
    color: $error;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
  }

  &--button_box {
    @include flex(row, start, center);
    gap: 20px;
  }
}

.success_alert {
  border: 1px solid #99D04F;
}

.error_alert {
  border: 1px solid $error;
}

.bold {
  font-weight: 600;
}

.pending-deletion {
  background-image: url("../styles/icons/processing-time-delete.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px !important;
  height: 20px !important;
}
.pending-addition {
  background-image: url("../styles/icons/in-progress-add.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px !important;
  height: 20px !important;
}
.warning-flex {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: inherit !important;
}

.loading_block {
  @include flex(row, center, center);
  height: 100%;

  &--loader {
    background-image: url("../styles/icons/Loader.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px !important;
    height: 50px !important;
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.customer_info_input {
  width: 100%;
}

.dialog_block {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  height: calc(100% - 114px);
  border-radius: 20px;
  border: 1px solid #DDE2E5;
  padding: 30px;
  margin: 30px;
  box-shadow: none;
  .paper_block {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    .warning_img {
      background-image: url("../styles/icons/alert.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: flex;
      margin-bottom: 12px;
    }
    .paper_title {
      color: #333B51;
      font-size: 24px;
      padding: 16px 0;
      text-align: center;
      font-weight: 600;
    }
    .paper_subtitle {
      font-size: 16px;
      text-align: center;
      color: #5E6366;
      line-height: 1.7;
    }
  }
  .paper_button_box {
    flex-direction: row;
    justify-content: center;
    display: flex;
    align-items: center;
    grid-gap: 20px;
  }
}

.filled {
  background-color: #EBEEF8 !important;
}
