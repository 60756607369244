$text_dark:#333B51;
$text_grey:#808080;

$dark_grey:#999999;
$bgk_gray:#F2F2F2;

$white:#fff;
$periwinkle_blue: #4A85FF;

$error:#F44336;
$bgk-pink:#ED1C24;

$orange: #F15A24;
$bgk-yellow: #FFF9D9;

/* New Colors*/

$lightgrey: #ebebeb;

$primary_black:#484647;

$primary_blue: #040844;

$primary_purple: #4A85FF;




